/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */
import { useNextRouter } from '@/data/Content/_NextRouter';
import { useSettings } from '@/data/Settings';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { expand, shrink } from '@/data/utils/keyUtil';
import { customDefaultCatGroup } from 'integration/generated/custom';
import { DefaultCatGroup } from 'integration/generated/custom/DefaultCatGroup';
import { ComDivalCommerceRestCatalogHandlerDefaultCatGroupHandlerGetDefaultCatGroupResponse } from 'integration/generated/custom/data-contracts';
import useSWR from 'swr';

const DATA_KEY = 'Breadcrumb';

type Props = Parameters<DefaultCatGroup['getDefaultCatGroup']>;
export const defaultCatGroupFetcher =
	(pub: boolean) =>
	async (
		storeId: Props[0]
	): Promise<
		ComDivalCommerceRestCatalogHandlerDefaultCatGroupHandlerGetDefaultCatGroupResponse | undefined
	> =>
		await customDefaultCatGroup(pub).getDefaultCatGroup(storeId);

export const useDefaultCatGroup = () => {
	const { settings } = useSettings();
	const router = useNextRouter();
	const { storeId } = getClientSideCommon(settings, router);

	const { data, error } = useSWR(
		storeId ? [shrink({ storeId }), DATA_KEY] : null,
		async ([props]) => {
			const expanded = expand<Record<string, any>>(props);
			const { storeId } = expanded;
			return defaultCatGroupFetcher(true)(storeId);
		}
	);

	return {
		data,
		loading: !error && !data,
		error,
	};
};
