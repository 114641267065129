/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { defaultCatGroupFetcher } from '@/data/Content/DefaultCatGroup';
import { useExtraRequestParameters } from '@/data/Content/_ExtraRequestParameters';
import { useNextRouter } from '@/data/Content/_NextRouter';
import { productFetcher } from '@/data/Content/_Product';
import { getContractIdParamFromContext, getSettings, useSettings } from '@/data/Settings';
import { getUser, User, useUser } from '@/data/User';
import { getPageDataFromId, usePageDataFromId } from '@/data/_PageDataFromId';
import { getServerCacheScope } from '@/data/cache/getServerCacheScope';
import { ID } from '@/data/types/Basic';
import { Breadcrumb, HCLBreadcrumb } from '@/data/types/Breadcrumb';
import { ContentProps } from '@/data/types/ContentProps';
import { ProductQueryResponse, ProductType } from '@/data/types/Product';
import { constructRequestParamsWithPreviewToken } from '@/data/utils/constructRequestParams';
import { extractContentsArray } from '@/data/utils/extractContentsArray';
import { getClientSideCommon } from '@/data/utils/getClientSideCommon';
import { getServerSideCommon } from '@/data/utils/getServerSideCommon';
import { expand, shrink } from '@/data/utils/keyUtil';
import { ComDivalCommerceRestCatalogHandlerDefaultCatGroupHandlerGetDefaultCatGroupResponse } from 'integration/generated/custom/data-contracts';
import { RequestParams } from 'integration/generated/query/http-client';
import { isEmpty, isNil, omitBy } from 'lodash';
import { GetServerSidePropsContext } from 'next';
import { useMemo } from 'react';
import useSWR, { unstable_serialize as unstableSerialize } from 'swr';

export const DATA_KEY = 'Breadcrumb';

// TODO: Breadcrumb has duplicated call to products endpoint on product page and PLP page,
// can be optimized with SWR to avoid extra calls.

export const fetcher =
	(pub: boolean, context?: GetServerSidePropsContext, user?: User) =>
	/**
	 * The breadcrumb trail data fetcher.
	 * @param props The props used for the request to build query.
	 * @param params The RequestParams, it contains all the info that a request needed except for 'body' | 'method' | 'query' | 'path'.
	 *                                  we are using it to send cookie header.
	 * @returns breadcrumb
	 */
	async (
		props: {
			storeId: string;
			catalogId: string;
			tokenName: string;
			tokenExternalValue: string;
			tokenValue: string;
			langId: string;
		} & Record<string, ID | ID[] | boolean | undefined>,
		params: RequestParams
	): Promise<any | undefined> => {
		const { storeId, catalogId, tokenName, tokenExternalValue, langId, tokenValue, contractId } =
			props;

		let categoryId = tokenValue;
		let query;
		let product: ProductType | null;

		// if this page is for a product, first fetch the product to determine its parent category
		if (tokenName === 'ProductToken') {
			query = {
				storeId,
				catalogId,
				langId,
				partNumber: [tokenExternalValue],
				contractId,
			};

			const res = (await productFetcher(pub, context)(query, params)) as ProductQueryResponse;
			product = extractContentsArray(res)?.at(0) ?? null;
			const parentCatalogGroupID = product?.parentCatalogGroupID;

			// DVL logic for getting correct categoryId based on custom org nav existing or not for product and user/org.
			const orgNavigationCatGroupId = user?.x_org_nav?.id ? user?.x_org_nav?.id : '';
			const allProductsCatGroupId = (await getAllProductsCatGroupId(user, pub, storeId)).toString();
			categoryId = getCategoryId(
				parentCatalogGroupID,
				orgNavigationCatGroupId,
				allProductsCatGroupId
			);

			/* --oringal logic--
			categoryId =
				(Array.isArray(parentCatalogGroupID) ? parentCatalogGroupID.at(-1) : parentCatalogGroupID)
					?.toString()
					.split('/')
					.at(-1) || '';
			*/
		} else {
			product = null;
		}

		// fetch the category's breadcrumb using products-by-category response -- this is heavy -- we should
		// consider using an alternate profile that perhaps only fetches the breadcrumb
		query = {
			storeId,
			catalogId,
			langId,
			categoryId,
			limit: 1,
			contractId,
		};

		const { breadCrumbTrailEntryView = [] } =
			tokenName === 'CategoryToken' || tokenName === 'ProductToken'
				? ((await productFetcher(pub, context)(query, params)) as ProductQueryResponse) ?? {}
				: {};
		return [breadCrumbTrailEntryView, product];
	};

export const dataMap = (data: Array<HCLBreadcrumb[] | ProductType>): Breadcrumb[] => {
	let breadcrumbs: HCLBreadcrumb[];

	if (data?.length) {
		const breadcrumb = data[0] as HCLBreadcrumb[];
		const product = data[1] as ProductType;
		breadcrumbs = [...breadcrumb];
		if (product) {
			breadcrumbs.push({ label: product.name, value: product.id, type: 'PRODUCT' });
		}
	} else {
		breadcrumbs = [];
	}

	return breadcrumbs.map(
		({ label, value, seo, type }) =>
			omitBy({ label, value, type, href: seo?.href }, isNil) as unknown as HCLBreadcrumb
	);
};

export const getBreadcrumbTrail = async ({ cache, id: _id, context }: ContentProps) => {
	const pageData = await getPageDataFromId(cache, context.query.path, context);
	const { tokenName = '', tokenValue = '', tokenExternalValue = '' } = pageData ?? {};
	const settings = await getSettings(cache, context);
	const user = await getUser(cache, context);
	const { storeId, defaultCatalogId: catalogId, langId } = getServerSideCommon(settings, context);
	const props = {
		tokenName,
		tokenValue,
		tokenExternalValue,
		storeId,
		catalogId,
		langId,
		...getContractIdParamFromContext(user?.context),
	};
	const cacheScope = getServerCacheScope(context, user.context);
	const key = unstableSerialize([shrink(props), DATA_KEY]);
	const params = constructRequestParamsWithPreviewToken({ context });
	let rc;

	const value = cache.get(key, cacheScope);
	if (value) {
		rc = await value;
	} else {
		rc = dataMap(await fetcher(false, context, user)(props, params));
		cache.set(key, Promise.resolve(rc), cacheScope);
	}

	return rc;
};

export const useBreadcrumbTrail = () => {
	const router = useNextRouter();
	const { settings } = useSettings();
	const { user } = useUser();
	const { storeId, langId, defaultCatalogId: catalogId } = getClientSideCommon(settings, router);
	const params = useExtraRequestParameters();
	const { data: pageData } = usePageDataFromId();
	const { tokenName = '', tokenValue = '', tokenExternalValue = '' } = pageData ?? {};
	const { data, error } = useSWR(
		tokenName && storeId
			? [
					shrink({
						tokenName,
						tokenValue,
						tokenExternalValue,
						storeId,
						catalogId,
						langId,
						...getContractIdParamFromContext(user?.context),
					}),
					DATA_KEY,
				]
			: null,
		async ([props]) => dataMap(await fetcher(true)(expand(props), params))
	);

	// remove hidden parent category and display child categories short description
	const formattedData = useMemo(() => {
		if (!isEmpty(data) && data !== undefined && data[0].label?.toLowerCase() === 'hidden') {
			const filteredData = data?.filter((item) => item?.label?.toLowerCase() !== 'hidden');
			if (!isEmpty(filteredData) && filteredData.length > 0) {
				filteredData[0].isChildOfHidden = true;
			}
			return filteredData;
		} else {
			return data;
		}
	}, [data]);

	return { breadcrumb: formattedData, uniqueId: tokenValue, error };
};

const getCategoryId = (
	parentCatalogGroupID: any,
	orgNavigationCatGroupId: string,
	allProductsCatGroupId: string
) => {
	// Ensure parentCatalogGroupID is always an array
	const validCatalogGroupID = normalizeToArray(parentCatalogGroupID);

	// If orgNavigationCatGroupId is valid, find the last matching path
	const matchedCategory = shouldFindMatch(orgNavigationCatGroupId)
		? findMatchingCategory(validCatalogGroupID, orgNavigationCatGroupId)
		: null;

	// If no match is found, try to find a match for allProductsCatGroupId
	const fallbackCategory =
		matchedCategory ||
		(shouldFindMatch(allProductsCatGroupId)
			? findMatchingCategory(validCatalogGroupID, allProductsCatGroupId)
			: null);

	// Extract last segment from matched or fallback path
	const value = extractLastSegment(fallbackCategory || validCatalogGroupID.at(0) || '');

	return value;
};

const normalizeToArray = (input: any) => {
	if (Array.isArray(input)) return input;
	return typeof input === 'string' ? [input] : [];
};

const shouldFindMatch = (orgCatGroupId: string) => orgCatGroupId !== null && orgCatGroupId !== '';

const findMatchingCategory = (categories: any, orgCatGroupId: string) =>
	[...categories].reverse().find((path) => path.includes(orgCatGroupId.toString()));

const extractLastSegment = (path: string) => path.toString().split('/').at(-1) || '';

const getAllProductsCatGroupId = async (user: User | undefined, pub: boolean, storeId: string) => {
	let value = user?.x_all_products_category ? user?.x_all_products_category : '';

	if (value === null || value === '') {
		const defaultCatGroupResp = (await defaultCatGroupFetcher(pub)(
			storeId
		)) as ComDivalCommerceRestCatalogHandlerDefaultCatGroupHandlerGetDefaultCatGroupResponse;

		if (
			defaultCatGroupResp?.defaultCatGroup !== undefined &&
			defaultCatGroupResp?.defaultCatGroup !== null
		) {
			value = defaultCatGroupResp?.defaultCatGroup?.toString();
		}
	}

	return value;
};
